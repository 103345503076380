@import url('https://fonts.googleapis.com/css2?family=Playwrite+AT:ital,wght@0,100..400;1,100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+AT:ital,wght@0,100..400;1,100..400&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

.playwrite-at-regular {
    font-family: "Playwrite AT", cursive;
    font-optical-sizing: auto;
    font-weight: < weight >;
    font-style: normal;
    font-stretch: ultra-expanded;
    color: #FFB7C3;
}

.titillium-web-extralight {
    font-family: "Titillium Web", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.titillium-web-light {
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.titillium-web-regular {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.titillium-web-semibold {
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.titillium-web-bold {
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.titillium-web-black {
    font-family: "Titillium Web", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.titillium-web-extralight-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.titillium-web-light-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.titillium-web-regular-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.titillium-web-semibold-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.titillium-web-bold-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-style: italic;
}

h1 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #454851;
}
